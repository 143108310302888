class NewFeatures {
    GRIDSTACK_NAME = 'new_features';
    DATA;

    constructor() {
        app.DASHBOARD_CORE.GRAPH_CLASSES[this.GRIDSTACK_NAME] = this;
    }

    deleteNewFeatures($el, r) {
        // add html to dom
        $el.html(r);
        // add dom events
        $el.find('a.btn-delete-new-fea').on('click', (e) => {
            // remove default click action
            e.preventDefault();
            if(confirm('Are you sure you want to delete this feature?')) {
                window.location = app.CACHE.URL_ADMIN + 'new_fea_delete/'+$(e.currentTarget).attr('data');
            }
        });
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new NewFeatures();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
